import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Container from '~components/Container';
import VideoHighlights from '~components/VideoHighlights/VideoHighlights';
import BottomShapeDivider from '~images/shape-dividers/ab-labs-shape-divider-03.svg';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '90px',
    paddingBottom: '90px'
  },
  titleArea: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '40px'
  },
  title: {
    fontSize: '64px',
    lineHeight: '76px',
    fontWeight: 500
  },
  section: {
    position: 'relative',
    backgroundColor: '#FFFFFF'
  },
  bottomShapeDivider: {
    width: '100vw',
    position: 'relative',
    bottom: '-10px'
  }
}));

const Highlights = ({ title, videos }) => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <Container center className={classes.container}>
        <div className={classes.titleArea}>
          <Typography className={classes.title}>{title}</Typography>
        </div>
        <VideoHighlights videos={videos} />
      </Container>
      <img src={BottomShapeDivider} alt="shape-divider" className={classes.bottomShapeDivider} />
    </div>
  );
};

Highlights.propTypes = {
  title: PropTypes.string.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      thumbnail: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
      }).isRequired,
      label: PropTypes.string,
      duration: PropTypes.string
    })
  ).isRequired
};

Highlights.defaultProps = {};

export default Highlights;
