import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DescriptionDialog from './DescriptionDialog';

const useStyles = makeStyles(() => ({
  name: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBlockStart: '16px',
    marginBlockEnd: '0px'
  },
  position: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 'normal',
    textAlign: 'center',
    marginBlockStart: '8px',
    marginBlockEnd: '16px'
  },
  readMoreRow: {
    textAlign: 'center'
  },
  readMoreBtn: {
    color: '#317DC9',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '22px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  avatar: {
    margin: '0 auto',
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '260px',
    borderRadius: '28px'
  }
}));

const TeamMembers = ({ members }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const [selectedMember, setSelectedMember] = useState(null);
  const onReadMoreClick = member => {
    setSelectedMember(member);
  };
  const onCloseDescriptionDialog = () => setSelectedMember(null);

  return (
    <>
      {selectedMember && (
        <DescriptionDialog show onClose={onCloseDescriptionDialog} {...selectedMember} />
      )}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={isXs ? 2 : 6}
      >
        {members &&
          members.map(member => (
            <Grid item xs={12} sm={6} md={3} key={member.name}>
              <img
                src={member.avatar && member.avatar.url}
                alt={member.avatar && member.avatar.alt}
                className={classes.avatar}
              />
              <Typography className={classes.name}>{member.name}</Typography>
              <Typography className={classes.position}>{member.position}</Typography>
              {member?.description?.html && (
                <Typography variant="body2" className={classes.readMoreRow}>
                  <span
                    tabIndex={0}
                    className={classes.readMoreBtn}
                    onClick={() => onReadMoreClick(member)}
                    role="button"
                    onKeyDown={event => {
                      if (
                        ['keydown', 'keypress'].includes(event.type) &&
                        ['Enter', ' '].includes(event.key)
                      ) {
                        onReadMoreClick(member);
                      }
                    }}
                  >
                    Read More
                  </span>
                </Typography>
              )}
            </Grid>
          ))}
      </Grid>
    </>
  );
};

TeamMembers.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
      }).isRequired,
      position: PropTypes.string,
      description: PropTypes.shape({
        html: PropTypes.string
      })
    })
  ).isRequired
};

export default TeamMembers;
