import { get } from 'lodash';
import { extractSeoData, getDuration } from '~src/utils/unfurl-helpers';

const RESOURCE_TYPENAME = {
  PLAYLIST: 'PrismicPlaylist',
  PODCAST_PLAYLIST: 'PrismicPodcastPlaylist'
};

const extractResource = highlight => {
  const resource = get(highlight, 'video');
  const type = get(resource, 'document.__typename');

  const explicitImage = get(highlight, 'thumbnail', null);
  const customDuration = get(highlight, 'duration', '');
  const label = get(highlight, 'label', '');
  const url = get(resource, 'document.url', '');
  const uid = get(resource, 'document.uid', '');

  switch (type) {
    case RESOURCE_TYPENAME.PLAYLIST: {
      const durationInSeconds = get(resource, 'document.data.videos', []).reduce(
        (acc, video) => acc + get(video, 'video.document.data.length_in_seconds', 0),
        0
      );
      const duration = getDuration(customDuration, durationInSeconds);
      return {
        title: get(resource, 'document.data.title'),
        thumbnail: explicitImage?.url ? explicitImage : get(resource, 'document.data.cover_image'),
        url,
        duration,
        label,
        uid
      };
    }
    case RESOURCE_TYPENAME.PODCAST_PLAYLIST: {
      const durationInSeconds = get(resource, 'document.data.episodes', []).reduce(
        (acc, episode) =>
          acc +
          get(episode, 'items', []).reduce(
            (subAcc, item) =>
              subAcc + get(item, 'podcast_episode.document.data.length_in_seconds', 0),
            0
          ),
        0
      );
      const duration = getDuration(customDuration, durationInSeconds);
      return {
        title: get(resource, 'document.data.podcast_title.text'),
        thumbnail: explicitImage?.url
          ? explicitImage
          : get(resource, 'document.data.podcast_thumbnail'),
        url,
        duration,
        label,
        uid
      };
    }

    default:
      return {};
  }
};

export default prismicData => ({
  seo: extractSeoData(prismicData, 'prismicAlephBetaLabs'),
  letter: {
    content: get(prismicData, 'prismicAlephBetaLabs.data.letter_content', null),
    letter_logo: get(prismicData, 'prismicAlephBetaLabs.data.letter_logo', null),
    signature_image: get(prismicData, 'prismicAlephBetaLabs.data.signature_image', null)
  },
  team: {
    title: get(prismicData, 'prismicAlephBetaLabs.data.team_section_title', ''),
    description: get(prismicData, 'prismicAlephBetaLabs.data.team_section_description', null),
    members: get(prismicData, 'prismicAlephBetaLabs.data.team_members', []).map(data => ({
      name: get(data, 'member.document.data.name', ''),
      position: get(data, 'member.document.data.position', ''),
      avatar: get(data, 'member.document.data.avatar', null),
      description: get(data, 'member.document.data.description', null)
    }))
  },
  highlights: {
    title: get(prismicData, 'prismicAlephBetaLabs.data.highlight_title', ''),
    videos: get(prismicData, 'prismicAlephBetaLabs.data.highlight_videos', []).map(data =>
      extractResource(data)
    )
  },
  speakpipe: {
    title: get(prismicData, 'prismicAlephBetaLabs.data.speak_title', ''),
    description: get(prismicData, 'prismicAlephBetaLabs.data.speak_description', null),
    cta_text: get(prismicData, 'prismicAlephBetaLabs.data.speak_cta', ''),
    cta_url: get(prismicData, 'prismicAlephBetaLabs.data.speak_cta_url', null),
    hero_image: get(prismicData, 'prismicAlephBetaLabs.data.speak_hero_image', null)
  }
});
