import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Container from '~components/Container';
import RichText from '~components/RichText';
import TeamMembers from '~components/TeamMembers/TeamMembers';
import BottomShapeDivider from '~images/shape-dividers/ab-labs-shape-divider-02.svg';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '90px',
    paddingBottom: '180px'
  },
  title: {
    fontSize: '52px',
    fontWeight: 500,
    lineHeight: '61px',
    marginBottom: '33px',
    textAlign: 'center'
  },
  description: {
    textAlign: 'center',
    marginBottom: '100px',
    maxWidth: '575px',
    '& > p': {
      fontSize: '16px',
      lineHeight: '26px',
      marginBlockStart: 0,
      marginBlockEnd: 0
    },
    '& > h1': {
      fontSize: '26px',
      lineHeight: '32px',
      marginBlockStart: 0,
      marginBlockEnd: 0
    },
    '& > h2': {
      fontSize: '24px',
      lineHeight: '30px',
      marginBlockStart: 0,
      marginBlockEnd: 0
    },
    '& > h3': {
      fontSize: '22px',
      lineHeight: '28px',
      marginBlockStart: 0,
      marginBlockEnd: 0
    },
    '& > h4': {
      fontSize: '20px',
      lineHeight: '26px',
      marginBlockStart: 0,
      marginBlockEnd: 0
    },
    '& > h6': {
      fontSize: '18px',
      lineHeight: '26px',
      marginBlockStart: 0,
      marginBlockEnd: 0
    }
  },
  section: {
    position: 'relative',
    backgroundColor: '#C9E4FF'
  },
  bottomShapeDivider: {
    width: '100vw',
    position: 'absolute',
    bottom: 0,
    backgroundColor: '#FFFFFF'
  }
}));

const Letter = ({ members, title, description }) => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <Container center className={classes.container}>
        <Typography className={classes.title}>{title}</Typography>
        <RichText
          html={description && description.html}
          externalClassName={classes.description}
          verticalSpacing={0}
        />
        <TeamMembers members={members} />
      </Container>
      <img src={BottomShapeDivider} alt="shape-divider" className={classes.bottomShapeDivider} />
    </div>
  );
};

Letter.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string
  }).isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
      }).isRequired,
      position: PropTypes.string,
      description: PropTypes.shape({
        html: PropTypes.string
      })
    })
  ).isRequired
};

Letter.defaultProps = {};

export default Letter;
