import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Page from '~layout/Page';
import SEO from '~layout/SearchEngineOptimization';
import unfurl from '~prismic/unfurl/aleph-beta-labs';
import Letter from '~modules/aleph-beta-labs/Letter';
import Team from '~modules/aleph-beta-labs/Team';
import Highlights from '~modules/aleph-beta-labs/Highlights';
import Speakpipe from '~modules/aleph-beta-labs/Speakpipe';

const AlephBetaLabs = ({ data }) => {
  const { seo, letter, team, highlights, speakpipe } = unfurl(data);

  return (
    <Page>
      <SEO {...seo} />
      <Letter {...letter} />
      <Team {...team} />
      <Highlights {...highlights} />
      <Speakpipe {...speakpipe} />
    </Page>
  );
};
AlephBetaLabs.propTypes = {
  data: PropTypes.shape().isRequired
};

AlephBetaLabs.defaultProps = {};

export default withPrismicPreview(AlephBetaLabs);

export const query = graphql`
  {
    prismicAlephBetaLabs {
      _previewable
      data {
        letter_content {
          html
        }
        letter_logo {
          url
          alt
        }
        signature_image {
          url
          alt
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          url
        }
        team_section_title
        team_section_description {
          html
        }
        team_members {
          member {
            document {
              ... on PrismicTeamMember {
                data {
                  name
                  position
                  avatar {
                    url
                    alt
                  }
                  description {
                    html
                  }
                }
              }
            }
          }
        }
        highlight_title
        highlight_videos {
          thumbnail {
            url
            alt
          }
          label
          duration
          video {
            document {
              __typename
              ... on PrismicPlaylist {
                uid
                url
                data {
                  title
                  cover_image {
                    url
                    alt
                  }
                  videos {
                    video {
                      document {
                        ... on PrismicVideo {
                          data {
                            length_in_seconds
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicPodcastPlaylist {
                uid
                url
                data {
                  podcast_title {
                    text
                  }
                  podcast_thumbnail {
                    url
                    alt
                  }
                  episodes {
                    ... on PrismicPodcastPlaylistDataEpisodesEpisode {
                      items {
                        podcast_episode {
                          document {
                            ... on PrismicPodcastEpisode {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        speak_title
        speak_description {
          html
        }
        speak_cta
        speak_cta_url {
          target
          url
        }
        speak_hero_image {
          alt
          url
        }
      }
    }
  }
`;
