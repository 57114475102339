import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import VideoHighlightCard from './VideoHighlightCard';

const GRID_STRUCTURE = [
  { xs: 12, sm: 12, md: 6 },
  { xs: 12, sm: 6, md: 3 },
  { xs: 12, sm: 6, md: 3 },
  { xs: 12, sm: 6, md: 3 },
  { xs: 12, sm: 6, md: 3 },
  { xs: 12, sm: 12, md: 6 }
];

const useStyles = makeStyles(theme => ({
  videoGrid: {
    flexGrow: 1,
    width: '100%'
  },
  gridItem: {
    [theme.breakpoints.down('xs')]: {
      padding: '8px 12px !important'
    }
  }
}));

const VideoHighlights = ({ videos }) => {
  const classes = useStyles();

  return (
    <div className={classes.videoGrid}>
      <Grid container spacing={3}>
        {videos.map((video, index) => (
          <Grid
            key={video?.uid}
            item
            {...GRID_STRUCTURE[index % GRID_STRUCTURE.length]}
            className={classes.gridItem}
          >
            <VideoHighlightCard {...video} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
VideoHighlights.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      thumbnail: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
      }).isRequired,
      label: PropTypes.string,
      duration: PropTypes.string
    })
  ).isRequired
};
VideoHighlights.defaultProps = {};

export default VideoHighlights;
