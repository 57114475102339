import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import RichText from '~components/RichText';
import Container from '~components/Container';
import Link from '~components/Link';
import Typography from '@material-ui/core/Typography';
import BottomShapeDivider from '~images/shape-dividers/ab-labs-shape-divider-04.svg';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: '80px',
    paddingBottom: '4%',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '6%'
    }
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: '50px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  title: {
    fontSize: '52px',
    lineHeight: '62px',
    fontWeight: 500
  },
  description: {
    marginTop: '43px',
    marginBottom: '43px',
    maxWidth: '500px',
    '& > p': {
      fontSize: '16px',
      lineHeight: '26px',
      margin: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0
    },
    '& > p:empty': {
      height: '26px',
      margin: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0
    },
    '& > h1': {
      fontSize: '26px',
      lineHeight: '32px',
      margin: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0
    },
    '& > h2': {
      fontSize: '24px',
      lineHeight: '30px',
      margin: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0
    },
    '& > h3': {
      fontSize: '22px',
      lineHeight: '28px',
      margin: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0
    }
  },
  heroImageContainer: {
    [theme.breakpoints.up('lg')]: {
      marginRight: '-10%'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center'
    }
  },
  heroImage: {
    height: 'auto',
    maxWidth: 600,
    marginTop: '-42px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: 0,
      marginTop: '0px'
    }
  },
  ctaText: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 600,
    padding: '20px 45px',
    color: '#FFFFFF',
    width: 'fit-content'
  },
  readMoreBtn: {
    backgroundColor: '#4176FF',
    borderRadius: 40,
    width: 'fit-content',
    '&:hover': {
      backgroundColor: '#3C6CE8',
      textDecoration: 'none'
    }
  },
  section: {
    position: 'relative',
    backgroundColor: '#E9F4FF'
  },
  bottomShapeDivider: {
    width: '100vw',
    position: 'absolute',
    bottom: 0
  }
}));

const Speakpipe = ({ title, description, cta_text, cta_url, hero_image }) => {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Container className={classes.container}>
        <div className={classes.contentContainer}>
          <Box
            minWidth="50%"
            display="flex"
            flexDirection="column"
            gridColumnGap="5px"
            marginRight="30px"
          >
            <Typography className={classes.title}>{title}</Typography>
            <RichText
              html={description && description.html}
              externalClassName={classes.description}
            />
            <Link to={cta_url?.url} target={cta_url?.target} className={classes.readMoreBtn}>
              <Typography className={classes.ctaText}>{cta_text}</Typography>
            </Link>
          </Box>
          <div className={classes.heroImageContainer}>
            <img
              src={hero_image && hero_image.url}
              alt={hero_image && hero_image.alt}
              className={classes.heroImage}
            />
          </div>
        </div>
      </Container>
      <img src={BottomShapeDivider} alt="shape-divider" className={classes.bottomShapeDivider} />
    </div>
  );
};

Speakpipe.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  cta_text: PropTypes.string.isRequired,
  cta_url: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.string
  }).isRequired,
  hero_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired
};

export default Speakpipe;
