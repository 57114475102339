import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Container from '~components/Container';
import RichText from '~components/RichText';
import BottomShapeDivider from '~images/shape-dividers/ab-labs-shape-divider-01.svg';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    marginTop: '40px'
  },
  sheetWrapper: {
    height: '100px',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 28,
    borderTopRightRadius: 28
  },
  sheet: {
    backgroundColor: 'white',
    background: 'repeating-linear-gradient(white, white 25px, #8FC7FF 26px)',
    backgroundPositionY: '0px',
    padding: 0
  },
  sheetBody: {
    padding: '26px 0px 90px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '26px 4px 90px 100px'
    },
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  contentArea: {
    maxWidth: '570px'
  },
  sheetTextContainer: {
    marginTop: '26px'
  },
  holes: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    borderRight: '1px solid rgba(193, 9, 9, 0.22)',
    paddingRight: '60px',
    padding: '20px 60px 0px 0px'
  },
  hole: {
    height: '20px',
    width: '20px',
    backgroundColor: '#D6EAFF',
    borderRadius: '50%',
    marginLeft: '15px'
  },
  letterLogo: {
    height: '312px',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '182px'
    }
  },
  signatureImage: {
    width: '100%'
  },
  section: {
    position: 'relative',
    backgroundColor: '#D6EAFF'
  },
  bottomShapeDivider: {
    width: '100vw',
    position: 'absolute',
    bottom: 0
  }
}));

const Letter = ({ content, letter_logo, signature_image }) => {
  const classes = useStyles();
  const paperRef = useRef(null);
  const [holeCount, setHoleCount] = useState(0);

  useEffect(() => {
    const holeHeight = 20;
    const paperHeight = paperRef.current.clientHeight;
    const holes = Math.floor((paperHeight * 0.4) / holeHeight); // 40% of paper height taken as the total sum of hole heights
    setHoleCount(holes);
  }, [paperRef]);

  return (
    <div className={classes.section}>
      <Container center>
        <div ref={paperRef} className={classes.paper}>
          <div className={classes.holes}>
            {[...Array(holeCount).keys()].map(e => (
              <div key={`hole_${e}`} className={classes.hole} />
            ))}
          </div>
          <div className={classes.sheetWrapper} />
          <div className={classes.sheet}>
            <div className={classes.sheetBody}>
              <div className={classes.contentArea}>
                <Box display="flex" justifyContent="center" marginBottom="60px" marginTop="-27px">
                  <img
                    src={letter_logo && letter_logo.url}
                    alt={letter_logo && letter_logo.alt}
                    className={classes.letterLogo}
                  />
                </Box>
                <RichText html={content && content.html} mode="abLabsLetter" verticalSpacing={0} />
                <Box display="flex" marginBottom="52px" marginTop="44px">
                  {signature_image?.url && (
                    <img
                      src={signature_image.url}
                      alt={signature_image.alt}
                      className={classes.signatureImage}
                    />
                  )}
                </Box>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <img src={BottomShapeDivider} alt="shape-divider" className={classes.bottomShapeDivider} />
    </div>
  );
};

Letter.propTypes = {
  letter_logo: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  content: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  signature_image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  })
};

Letter.defaultProps = {
  signature_image: null
};

export default Letter;
