import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '~components/Link';
import { useTestId } from '~utils/set-testid';
import { getResourceTypeByRelativeUrl } from '~utils/playlist-helpers';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100% !important',
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'relative'
  },
  media: {
    height: 250,
    borderRadius: 15
  },
  title: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 500,
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    position: 'relative',
    paddingRight: '18px'
  },
  duration: {
    fontSize: 14,
    marginBottom: 0,
    marginLeft: 3
  },
  durationIcon: {
    fontSize: 13,
    color: '#FFFFFF'
  },
  arrowIcon: {
    fontSize: 13,
    lineHeight: '19px',
    marginLeft: '5px',
    position: 'absolute',
    right: 0,
    bottom: '3px'
  }
}));

const VideoHighlightCard = ({ url, title, thumbnail, label, duration }) => {
  const classes = useStyles();
  const cardType = getResourceTypeByRelativeUrl(url);
  const testId = useTestId(`${cardType}-item`);

  return (
    <Link to={url} underline="none" {...testId}>
      <Tooltip title={title} arrow>
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia className={classes.media} image={thumbnail?.url} title={thumbnail?.alt} />
            {label && (
              <Box
                component="span"
                position="absolute"
                left={15}
                fontSize={12}
                fontWeight={600}
                borderRadius={50}
                bgcolor="#FFFFFF"
                padding="4px 11px 4px 11px"
                sx={{ top: { xs: 9, sm: 15 } }}
              >
                {label}
              </Box>
            )}
            {duration && (
              <Box
                component="div"
                display="flex"
                height="fit-content"
                alignItems="center"
                position="absolute"
                right={15}
                top={15}
                fontSize={12}
                fontWeight={600}
                color="#FFFFFF"
              >
                <WatchLaterIcon className={classes.durationIcon} />
                <Typography
                  gutterBottom
                  variant="subtitle2"
                  component="p"
                  className={classes.duration}
                >
                  {duration}
                </Typography>
              </Box>
            )}
            {title && (
              <Box
                component="div"
                position="absolute"
                display="flex"
                height="fit-content"
                alignItems="center"
                justifyContent="space-between"
                left={15}
                bottom={15}
                width="90%"
                fontSize={12}
                fontWeight={600}
                borderRadius={9}
                bgcolor="#FFFFFF"
                padding="20px 11px"
              >
                <Typography variant="subtitle2" component="p" className={classes.title}>
                  {title}
                  <ArrowForwardIcon className={classes.arrowIcon} />
                </Typography>
              </Box>
            )}
          </CardActionArea>
        </Card>
      </Tooltip>
    </Link>
  );
};
VideoHighlightCard.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }).isRequired,
  label: PropTypes.string,
  duration: PropTypes.string
};
VideoHighlightCard.defaultProps = {
  label: '',
  duration: ''
};

export default VideoHighlightCard;
